<template>
  <v-app style="width:100%;height:100%" v-if="loginPageCtrl === 'show'">
    <div
      :style="{
        minHeight: '700px',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        padding: 0,
        margin: 0,
        backgroundImage: `url(${baseUrl}/upload/login/login_bg.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
      }"
    >
      <!-- 登录页Logo -->
      <Logo :logoDesc="logoDesc" :fontColor="fontColor"></Logo>

      <div
        :class="[
          loginSite === 'left'
            ? 'leftStyle d-flex'
            : loginSite === 'right'
            ? 'rightStyle d-flex'
            : 'centerStyle d-flex'
        ]"
      >
        <!-- 温馨提示 -->
        <Notice
          :sonData="sonData"
          v-if="
            (loginSite === 'right' || loginSite === 'center') &&
              noticeDisplay !== 'staticState'
          "
          :noticeShow="noticeShow"
          @noticeChange="noticeChange($event)"
        ></Notice>

        <!-- 静态温馨提示 -->
        <StaticNotice
          v-if="
            loginSite === 'right' &&
              showUseInstruction == true &&
              noticeDisplay === 'staticState'
          "
          :sonData="sonData"
        ></StaticNotice>

        <!-- 登录模块 -->
        <div
          class="white--text"
          :style="{
            position:
              (loginSite === 'right' &&
                (showUseInstruction == true || showUseInstruction == false) &&
                noticeDisplay === 'dynamic') ||
              (loginSite === 'right' &&
                showUseInstruction == false &&
                noticeDisplay === 'staticState')
                ? 'absolute'
                : 'relative',
            right:
              (loginSite === 'right' &&
                (showUseInstruction == true || showUseInstruction == false) &&
                noticeDisplay === 'dynamic') ||
              (loginSite === 'right' &&
                showUseInstruction == false &&
                noticeDisplay === 'staticState')
                ? '14%'
                : '0',
            width: '380px',
            minHeight: '480px',
            borderRadius: '6px',
            background: `rgba(${loginBgColor},${loginBgTransparency})`
          }"
        >
          <!-- 右上角的icon切换 -->
          <img
            v-if="showCode"
            @click="coverType"
            style="height:52px;position:absolute;right:4px;top:4px;color:#fff;cursor:pointer"
            :src="
              qrcode
                ? `data:image/png;base64,${consts.qrcodeUrl}`
                : `data:image/png;base64,${consts.passwordUrl}`
            "
          />

          <!-- 默认优先展示扫码 -->
          <!-- 右上角的icon切换 -->
          <img
            v-if="defaultCode"
            @click="coverDefaultCodeType"
            style="height:52px;position:absolute;right:4px;top:4px;color:#fff;cursor:pointer"
            :src="
              defaultCodeImg
                ? `data:image/png;base64,${consts.passwordUrl}`
                : `data:image/png;base64,${consts.qrcodeUrl}`
            "
          />

          <!-- 账户登录 -->
          <div
            v-if="!specifiedSource"
            class="pa-5 pt-8"
            :style="{ margin: qrcode ? '0 auto' : '16px auto' }"
          >
            <!-- tab切换 -->
            <div v-if="qrcode">
              <v-tabs
                v-if="tabSources.length > 0"
                fixed-tabs
                v-model="tabs"
                class="my-4"
                :color="highlightColor"
              >
                <v-tab
                  class="tab-item"
                  :style="styleObject"
                  v-for="source in tabSources"
                  :key="source.name"
                  @change="tabTap(source.id)"
                >
                  <span>{{ source.name }}</span>
                </v-tab>
              </v-tabs>

              <v-tabs v-else fixed-tabs class="my-4" :color="highlightColor">
                <v-tab class="tab-item" :style="styleObject">
                  <span>其他登录方式</span>
                </v-tab>
              </v-tabs>
            </div>
            <!--扫码登录方式 -->
            <v-tabs-items v-model="tabs" v-if="qrcode">
              <v-tab-item v-for="source in tabSources" :key="source.id">
                <div
                  v-if="
                    source.type === 'PASSWORD' ||
                      source.type === 'LDAP' ||
                      source.type === 'FNU_LDAP' ||
                      source.type === 'RADIUS' ||
                      source.type === 'SMS'
                  "
                >
                  <component
                    :is="loginCards[source.type]"
                    :sonData="sonData"
                    :curId="curId"
                    :curItem="curItem"
                    :fromType="fromType"
                    @showAccountEvent="showAccountEvent($event)"
                    @noticeChange="noticeChange($event)"
                  ></component>
                </div>
                <div v-else>
                  <div style="position: relative;margin-top:40px">
                    <!-- 扫码成功 -->
                    <div
                      class="text-caption d-flex flex-column justify-center"
                      v-if="scanSuccess"
                      style="width:270px;height:270px;overflow:hidden;background:#fff;opacity:.9;position:absolute;top:1px;left:35px"
                    >
                      <v-icon size="100" color="#07c160"
                        >mdi-check-circle</v-icon
                      >
                      <div class="text-center text-body-1">扫码成功</div>
                    </div>

                    <!-- 二维码已过期 -->
                    <div
                      v-if="isValid"
                      class="text-caption d-flex flex-column justify-center"
                      style="
                          width: 270px;
                          height: 270px;
                          overflow: hidden;
                          background: #fff;
                          opacity: 0.9;
                          position: absolute;
                          top: 1px;
                          left: 35px;
                        "
                    >
                      <v-icon size="100" color="#F5A623"
                        >mdi-alert-circle</v-icon
                      >
                      <div class="text-center text-body-1 mt-2 black--text">
                        二维码已过期
                        <span
                          @click="toRefresh"
                          class="blue--text"
                          style="cursor: pointer"
                          >刷新</span
                        >
                      </div>
                    </div>
                    <div
                      v-if="
                        source &&
                          (source.type === 'WECHAT_RZKC' ||
                            (source.type === 'WECHAT_ENTERPRISE' &&
                              source.wechat_qrcode))
                      "
                      class="text-caption d-flex justify-center align-center unselect"
                      style="width: 270px; height: 270px; margin: 0 auto; overflow: hidden; padding: 16px; background: #fff;"
                      id="wechat_rzkc_code"
                      ref="wechat_rzkc_code"
                    ></div>
                  </div>
                  <div
                    v-if="source && source.type === 'DING_TALK'"
                    class="px-2 py-2 text-caption d-flex justify-center align-center unselect"
                    style="width: 270px; height: 270px; margin: 0 auto; overflow: hidden"
                    id="ding_code"
                    ref="ding_code"
                  ></div>
                  <div
                    v-if="source && source.type === 'WECHAT_ENTERPRISE'"
                    class="px-2 py-2 text-caption d-flex justify-center align-center unselect"
                    style="width: 280px; height: 340px; margin: 0 auto; overflow: hidden"
                    id="enterprise_code"
                    ref="enterprise_code"
                  ></div>

                  <!-- 微信 -->
                  <div
                    v-if="source && source.type === 'WECHAT_WEBSITE'"
                    class="px-2 py-4 text-caption d-flex justify-center align-center unselect"
                    style="width:280px;height:340px;margin: 0 auto;overflow:hidden;"
                    id="wechatwebsite_code"
                    ref="wechatwebsite_code"
                  ></div>
                </div>
              </v-tab-item>
            </v-tabs-items>

            <!-- 第三方账号登陆 -->
            <OtherLink
              v-if="qrcode"
              :otherLogins="otherLogins"
              :tabSources="tabSources"
            ></OtherLink>

            <v-card
              flat
              v-else
              class="d-flex flex-column justify-center"
              :style="{
                height:
                  curCode && curCode.type === 'WECHAT_ENTERPRISE'
                    ? '394px'
                    : '350px'
              }"
            >
              <v-tabs fixed-tabs v-model="tabs" :color="highlightColor">
                <v-tab
                  class="tab-item"
                  :style="styleObject"
                  v-for="source in codeTabSources"
                  :key="source.name"
                  @change="tabTap(source.id)"
                >
                  <span>{{ source.name }}</span>
                </v-tab>
              </v-tabs>
              <!-- 扫码登录方式 -->
              <div
                style="position:relative;margin-top:40px"
                v-if="!showWechatAccount"
              >
                <!-- 扫码成功 -->
                <div
                  class="text-caption d-flex flex-column justify-center"
                  v-if="scanSuccess"
                  style="width:270px;height:270px;overflow:hidden;background:#fff;opacity:.9;position:absolute;top:1px;left:35px"
                >
                  <v-icon size="100" color="#07c160">mdi-check-circle</v-icon>
                  <div class="text-center text-body-1">扫码成功</div>
                </div>

                <!-- 二维码已过期 -->
                <div
                  v-if="isValid"
                  class="text-caption d-flex flex-column justify-center"
                  style="width:270px;height:270px;overflow:hidden;background:#fff;opacity:.9;position:absolute;top:1px;left:35px"
                >
                  <v-icon size="100" color="#F5A623">mdi-alert-circle</v-icon>
                  <div class="text-center text-body-1 mt-2">
                    二维码已过期
                    <span
                      @click="toRefresh"
                      class="blue--text"
                      style="cursor: pointer;"
                      >刷新</span
                    >
                  </div>
                </div>
                <div
                  v-if="
                    curCode &&
                      (curCode.type === 'WECHAT_RZKC' ||
                        (curCode.type === 'WECHAT_ENTERPRISE' &&
                          curCode.wechat_qrcode))
                  "
                  class="text-caption d-flex justify-center align-center unselect"
                  style="width:270px;height:270px;margin: 0 auto;overflow:hidden;background:#fff;"
                  id="wechat_rzkc_code"
                  ref="wechat_rzkc_code"
                ></div>
              </div>
              <div
                v-if="curCode && curCode.type === 'DING_TALK'"
                class="px-2 py-2 text-caption d-flex justify-center align-center unselect"
                style="width:270px;height:270px;margin: 0 auto;overflow:hidden;"
                id="ding_code"
                ref="ding_code"
              ></div>
              <div
                v-if="curCode && curCode.type === 'WECHAT_ENTERPRISE'"
                class="px-2 py-2 text-caption d-flex justify-center align-center unselect"
                style="width:280px;height:400px;margin: 0 auto;overflow:hidden;"
                id="enterprise_code"
                ref="enterprise_code"
              ></div>

              <!-- 微信 -->
              <div
                v-if="curCode && curCode.type === 'WECHAT_WEBSITE'"
                class="px-2 py-4 text-caption d-flex justify-center align-center unselect"
                style="width:280px;height:400px;margin: 0 auto;overflow:hidden;"
                id="wechatwebsite_code"
                ref="wechatwebsite_code"
              ></div>

              <!-- 微信用户绑定 -->
              <WechatBind
                v-if="showWechatAccount"
                :headimgurl="headimgurl"
                :nickname="nickname"
                :sonData="sonData"
                @doBind="doBind"
                @cancelBind="cancelBind"
              ></WechatBind>
            </v-card>
          </div>
          <div
            v-else
            class="pa-5 pt-8"
            :style="{ margin: qrcode ? '0 auto' : '16px auto' }"
          >
            <!-- tab切换 -->
            <v-tabs
              v-if="qrcode"
              fixed-tabs
              v-model="tabs"
              class="my-4"
              :color="highlightColor"
            >
              <v-tab v-for="source in tabList" :key="source.name">
                <span>{{ source.name }}</span>
              </v-tab>
            </v-tabs>

            <!-- tab输入框 -->
            <v-tabs-items v-model="tabs" v-if="qrcode" style="height:330px">
              <v-tab-item>
                <component
                  :is="loginCards[specifiedSource.type]"
                  :sonData="sonData"
                  :curId="curId"
                  :curItem="curItem"
                  :fromType="fromType"
                  @showAccountEvent="showAccountEvent($event)"
                  @noticeChange="noticeChange($event)"
                ></component>
              </v-tab-item>
            </v-tabs-items>
            <v-card
              flat
              v-else
              class="d-flex flex-column justify-center"
              :style="{
                height:
                  curCode && curCode.type === 'WECHAT_ENTERPRISE'
                    ? '394px'
                    : '350px'
              }"
            >
              <v-tabs fixed-tabs v-model="tabs" :color="highlightColor">
                <v-tab
                  class="tab-item"
                  :style="styleObject"
                  v-for="source in codeTabSources"
                  :key="source.name"
                  @change="tabTap(source.id)"
                >
                  <span>{{ source.name }}</span>
                </v-tab>
              </v-tabs>
              <div
                style="position:relative;margin-top:40px"
                v-if="!showWechatAccount"
              >
                <!-- 扫码成功 -->
                <div
                  class="text-caption d-flex flex-column justify-center"
                  v-if="scanSuccess"
                  style="width:270px;height:270px;overflow:hidden;background:#fff;opacity:.9;position:absolute;top:1px;left:35px"
                >
                  <v-icon size="100" color="#07c160">mdi-check-circle</v-icon>
                  <div class="text-center text-body-1">扫码成功</div>
                </div>

                <!-- 二维码已过期 -->
                <div
                  v-if="isValid"
                  class="text-caption d-flex flex-column justify-center"
                  style="width:270px;height:270px;overflow:hidden;background:#fff;opacity:.9;position:absolute;top:1px;left:35px"
                >
                  <v-icon size="100" color="#F5A623">mdi-alert-circle</v-icon>
                  <div class="text-center text-body-1 mt-2">
                    二维码已过期
                    <span
                      @click="toRefresh"
                      class="blue--text"
                      style="cursor: pointer;"
                      >刷新</span
                    >
                  </div>
                </div>
                <div
                  v-if="
                    curCode &&
                      (curCode.type === 'WECHAT_RZKC' ||
                        (curCode.type === 'WECHAT_ENTERPRISE' &&
                          curCode.wechat_qrcode))
                  "
                  class="text-caption d-flex justify-center align-center unselect"
                  style="width:270px;height:270px;margin: 0 auto;overflow:hidden;padding:16px;background:#fff;"
                  id="wechat_rzkc_code"
                  ref="wechat_rzkc_code"
                ></div>
              </div>
              <div
                v-if="curCode && curCode.type === 'DING_TALK'"
                class="px-2 py-2 text-caption d-flex justify-center align-center unselect"
                style="width:252px;height:264px;margin: 0 auto;overflow:hidden;"
                id="ding_code"
                ref="ding_code"
              ></div>
              <div
                v-if="curCode && curCode.type === 'WECHAT_ENTERPRISE'"
                class="px-2 py-2 text-caption d-flex justify-center align-center unselect"
                style="width:280px;height:400px;margin: 0 auto;overflow:hidden;"
                id="enterprise_code"
                ref="enterprise_code"
              ></div>

              <!-- 微信 -->
              <div
                v-if="curCode && curCode.type === 'WECHAT_WEBSITE'"
                class="px-2 py-4 text-caption d-flex justify-center align-center unselect"
                style="width:280px;height:400px;margin: 0 auto;overflow:hidden;"
                id="wechatwebsite_code"
                ref="wechatwebsite_code"
              ></div>

              <!-- 微信用户绑定 -->
              <WechatBind
                v-if="showWechatAccount"
                :headimgurl="headimgurl"
                :nickname="nickname"
                :sonData="sonData"
                @doBind="doBind"
                @cancelBind="cancelBind"
              ></WechatBind>
            </v-card>
          </div>
        </div>

        <!-- 温馨提示 -->
        <Notice
          :sonData="sonData"
          v-if="loginSite === 'left' && noticeDisplay !== 'staticState'"
          :noticeShow="noticeShow"
          @noticeChange="noticeChange($event)"
        ></Notice>

        <!-- 静态温馨提示 -->
        <StaticNotice
          v-if="
            loginSite === 'left' &&
              showUseInstruction == true &&
              noticeDisplay === 'staticState'
          "
          :sonData="sonData"
        ></StaticNotice>
      </div>
      <!-- 底部版权文字 -->
      <FootDisplay :sonData="sonData" :footContent="footContent"></FootDisplay>
      <Snackbar />
      <ConfirmBox ref="confirm" />
    </div>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import ConfirmBox from "@/components/ConfirmBox";
import QRCode from "qrcodejs2";
import { api_request } from "@/util/network";
import { CONSTS } from "../utils/base";
import {
  randomSessionId,
  getCurTime,
  HexToRgb,
  externalBrowser,
  isSourceId,
  isCode
} from "@/util/util";
import Logo from "../pcComp/Logo";
import PcPasswordCard from "../pcComp/PcPasswordCard";
import PcSmsCard from "../pcComp/PcSmsCard";
import PcFNULdapCard from "../pcComp/PcFNULdapCard";
import Notice from "../pcComp/Notice";
import StaticNotice from "../pcComp/StaticNotice";
import OtherLink from "../pcComp/OtherLink";
import WechatBind from "../pcComp/WechatBind";
import FootDisplay from "../pcComp/FootDisplay";

export default {
  name: "login",
  inject: ["reload"],
  data() {
    return {
      tabs: null,
      tabList: [],
      allTabList: [],
      logoDesc: "",
      baseUrl: "",
      loginBgColor: "#000",
      loginBgTransparency: 0.5,
      loginFontColor: "white",
      highlightColor: "#FF0000",
      loginSite: "right",
      footContent: "版权所有 北京瑞智康诚科技有限公司 2021",
      fontColor: "",
      footprint: "",
      scanCode: "",
      qrcode: true, //4
      notice: "",
      noticeShow: false,
      showUseInstruction: false,
      curItem: {},
      curId: null,
      validTimer: 60,
      scanSuccess: false,
      isValid: false,
      showCode: false,
      curCode: {},
      consts: CONSTS,
      tabSources: [],
      codeTabSources: [],
      linkSources: [],
      otherLogins: [],
      specifiedSource: null,
      loginCards: {
        PASSWORD: PcPasswordCard,
        SMS: PcSmsCard,
        LDAP: PcPasswordCard,
        RADIUS: PcPasswordCard,
        FNU_LDAP: PcFNULdapCard
      },
      sonData: {},
      loginPageCtrl: null,
      getTabs: [],
      otherLinks: [],
      getQrcode: "",
      ding_appid: "",
      ding_redirecturi: "",
      noticeDisplay: "",
      loginStyle: "",
      showWechatAccount: false,
      headimgurl: "",
      nickname: "",
      fromType: "",
      loginAccountInfo: "",
      showDefaultQrcode: false,
      defaultCode: false,
      defaultCodeImg: false
    };
  },
  async mounted() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.getTabList();
    await this.fetchData();
  },
  methods: {
    //温馨提示
    noticeChange(data) {
      this.noticeShow = data;
    },
    // 控制绑定账户展示
    showAccountEvent(data) {
      this.qrcode = false;
      this.showWechatAccount = data.showAccount;
      this.loginAccountInfo = data.accountInfo;
    },
    // 确认绑定并继续
    doBind(flag) {
      let bindData = JSON.parse(localStorage.getItem("bindData"));
      let session_id1 = JSON.parse(localStorage.getItem("session_id1"));
      let payload = {};

      if (bindData && session_id1) {
        let { identity_token } = bindData;
        let { session } = session_id1;
        if (!flag) {
          payload = {
            session_id: session,
            identity_token
          };
        } else {
          payload = {
            session_id: session,
            identity_token,
            ignore_bound: true
          };
        }
      }
      return this.$http
        .post(`api/auth/bind_account`, payload)
        .delegateTo(api_request)
        .then(data => {
          if (data) {
            window.location = data["return_url"];
          } else {
            let cellMode = localStorage.getItem("cellMode");
            let iconStatus = localStorage.getItem("iconStatus");
            localStorage.clear();
            localStorage.setItem("cellMode", cellMode);
            localStorage.setItem("iconStatus", iconStatus);
            this.$store.commit("auth_success", this.loginAccountInfo);
            if (this.loginAccountInfo["return_url"]) {
              window.location = this.loginAccountInfo["return_url"];
            } else {
              this.$router.push({ path: "/" });
            }
            return "绑定成功";
          }
        })
        .catch(({ code, message }) => {
          if (code === "AccountAlreadyBoundSource") {
            this.isChangeBind();
          } else {
            localStorage.removeItem("session_id");
            localStorage.removeItem("bindData");
            throw `绑定失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 取消绑定
    async cancelBind() {
      await this.logout();
      this.reload();
    },
    // 该用户已绑定其他微信号，是否换绑
    isChangeBind() {
      this.$refs.confirm
        .showConfirm({
          contentText: `该用户已绑定其他微信号，是否重新绑定为『${this.nickname}』？`,
          confirmText: "确认换绑",
          cancelText: "取消绑定并登出",
          callback: () => {
            this.doBind(true);
            return new Promise(resolve => resolve());
          }
        })
        .catch(() => {
          this.cancelBind();
        });
    },
    // 点击tab切换
    tabTap(source_id) {
      this.noticeShow = false;
      this.curId = source_id;
      this.curItem = this.filterSourceId(source_id, this.allTabList);
    },
    // 右上角图标切换
    async coverType() {
      this.qrcode = !this.qrcode;
      if (this.qrcode) {
        localStorage.removeItem("session_id1");
        this.isValid = false;
        this.scanSuccess = false;
        clearInterval(this.timer);
        this.validTimer = 60;
      } else {
        localStorage.removeItem("session_id1");
        this.curCode = this.filterItem(this.scanCode);
        this.codeTabSources = this.filterSource(this.scanCode, this.allTabList);
        this.startChallenge(this.curCode);
      }
    },
    // 默认优先展示扫码登录--->右上角图标切换
    coverDefaultCodeType() {
      this.defaultCodeImg = !this.defaultCodeImg;
      if (this.defaultCodeImg) {
        this.firstQrcodeRender(this.getQrcode);
      } else {
        clearInterval(this.timer);
        this.qrcode = true;
        this.coverOtherLogins();
      }
    },
    async startChallenge(curItem) {
      if (curItem.type === "WECHAT_RZKC") {
        let session = await randomSessionId();
        let sessionCode = localStorage.getItem("session_id1");
        let originName = window.location.origin;
        if (sessionCode) {
          if (document.getElementById("wechat_rzkc_code").innerHTML == "") {
            let qrcodeCreater = new QRCode(
              document.getElementById("wechat_rzkc_code"),
              `${originName}${
                process.env.VUE_APP_BASE_URL
              }/wechat/authorize?session=${JSON.parse(sessionCode).session}`
            );
            qrcodeCreater._el.title = "";
            this.valid();
            return;
          }
        } else {
          if (document.getElementById("wechat_rzkc_code").innerHTML == "") {
            localStorage.setItem(
              "session_id1",
              JSON.stringify({
                session,
                curTime: getCurTime()
              })
            );
            if (this.$refs.wechat_rzkc_code) {
              let qrcodeCreater = new QRCode(
                document.getElementById("wechat_rzkc_code"),
                `${originName}${process.env.VUE_APP_BASE_URL}/wechat/authorize?session=${session}`
              );
              qrcodeCreater._el.title = "";
              this.valid();
            }
          }
        }
      } else if (
        curItem.type === "WECHAT_ENTERPRISE" &&
        curItem.wechat_qrcode
      ) {
        let session = await randomSessionId();
        let sessionCode = localStorage.getItem("session_id1");
        let originName = window.location.origin;
        if (sessionCode) {
          if (document.getElementById("wechat_rzkc_code").innerHTML == "") {
            let qrcodeCreater = new QRCode(
              document.getElementById("wechat_rzkc_code"),
              `${originName}${
                process.env.VUE_APP_BASE_URL
              }/wxwork/authorize?session=${JSON.parse(sessionCode).session}`
            );
            qrcodeCreater._el.title = "";
            this.valid1();
            return;
          }
        } else {
          if (document.getElementById("wechat_rzkc_code").innerHTML == "") {
            localStorage.setItem(
              "session_id1",
              JSON.stringify({
                session,
                curTime: getCurTime()
              })
            );
            if (this.$refs.wechat_rzkc_code) {
              let qrcodeCreater = new QRCode(
                document.getElementById("wechat_rzkc_code"),
                `${originName}${process.env.VUE_APP_BASE_URL}/wxwork/authorize?session=${session}`
              );
              qrcodeCreater._el.title = "";
              this.valid1();
            }
          }
        }
      } else if (
        (curItem.type === "WECHAT_ENTERPRISE" && !curItem.wechat_qrcode) ||
        curItem.type === "WECHAT_WEBSITE" ||
        curItem.type === "DING_TALK"
      ) {
        return this.$http
          .get(`api/source/${curItem.id}/start`)
          .delegateTo(api_request)
          .then(async startRes => {
            if (startRes.type === "challenge") {
              let CURTYPE = curItem.type;
              switch (CURTYPE) {
                case "WECHAT_ENTERPRISE":
                  await this.loadJs("WECHAT_ENTERPRISE", startRes);
                  break;
                case "WECHAT_WEBSITE":
                  await this.loadJs("WECHAT_WEBSITE", startRes);
                  break;

                case "DING_TALK":
                  await this.loadJs("DING_TALK", startRes);
                  break;

                default:
                  break;
              }
            } else if (startRes.type === "redirect_url") {
              location.href = startRes.content;
            }
          })
          .catch(({ code, message }) => {
            throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      } else {
        return;
      }
    },
    async loadJs(curNeedJs, startRes) {
      const wwJs =
        "//wwcdn.weixin.qq.com/node/wework/wwopen/js/wwLogin-1.2.4.js";
      const wxJs = "//res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      const dingJs = "//g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js";
      await new Promise((resolve, reject) => {
        // @see: https://stackoverflow.com/questions/8578617
        let scriptEle = document.createElement("script");
        scriptEle.type = "text/javascript";
        scriptEle.async = false;
        scriptEle.src =
          curNeedJs === "WECHAT_ENTERPRISE"
            ? wwJs
            : curNeedJs === "WECHAT_WEBSITE"
            ? wxJs
            : dingJs;
        scriptEle.onload = resolve;
        scriptEle.addEventListener("load", resolve);
        scriptEle.addEventListener("error", e => reject(e.error));
        document.head.appendChild(scriptEle);
      });
      switch (curNeedJs) {
        case "WECHAT_ENTERPRISE":
          this.wwLoginFn(
            startRes.content.appid,
            startRes.content.agentid,
            startRes.content.redirect_uri,
            startRes.content.state
          );
          break;
        case "WECHAT_WEBSITE":
          this.wxLoginFn(
            startRes.content.appid,
            startRes.content.redirect_uri,
            startRes.content.state
          );
          break;

        default:
          this.dingLoginFn(
            startRes.content.appid,
            startRes.content.redirect_uri
          );
          break;
      }
    },
    wwLoginFn(appid, agentid, redirect_uri, state) {
      return new window.WwLogin({
        id: "enterprise_code",
        appid,
        agentid,
        redirect_uri: encodeURIComponent(redirect_uri),
        state
      });
    },
    wxLoginFn(appid, redirect_uri, state) {
      let CODE = isCode();
      if (CODE) {
        this.wechatSiteLogin(redirect_uri, CODE);
      }
      return new window.WxLogin({
        id: "wechatwebsite_code",
        appid,
        redirect_uri: "http://port.webvpn.net.cn:40899/login",
        response_type: "code",
        scope: "snsapi_login",
        state,
        href:
          "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLnFyY29kZXttYXJnaW4tdG9wOjgwcHg7fQ=="
      });
    },
    dingLoginFn(appid, redirect_uri) {
      this.ding_appid = appid;
      this.ding_redirecturi = redirect_uri;
      return new window.DDLogin({
        id: "ding_code",
        goto: encodeURIComponent(
          `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=state&redirect_uri=${redirect_uri}`
        ),
        style: "border:none;background-color:transparent;",
        height: "350"
      });
    },
    // 钉钉事件
    dingEvent() {
      let handleMessage = function(event) {
        var origin = event.origin;
        if (origin == "https://login.dingtalk.com") {
          let loginTmpCode = event.data;
          if (loginTmpCode) {
            window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${this.ding_appid}&response_type=code&scope=snsapi_login&state=state&redirect_uri=${this.ding_redirecturi}&loginTmpCode=${loginTmpCode}`;
          }
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    },
    // 微信代理RZKC
    consumeSession() {
      return new Promise(resolve => {
        return this.$http
          .post(`api/auth/consume_session`, {
            session_id: JSON.parse(localStorage.getItem("session_id1")).session
          })
          .delegateTo(api_request)
          .then(data => {
            resolve(data);
          })
          .catch(({ code, message }) => {
            if (code === "QrcodeScanned") {
              this.scanSuccess = true;
              this.isValid = false;
            }

            throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .catch(() => {});
      });
    },
    valid() {
      this.timer = setInterval(async () => {
        if (this.validTimer > 0) {
          this.validTimer = this.validTimer - 1;
          if (this.validTimer % 2 === 0) {
            let res = await this.consumeSession();
            if (res.identity_token) {
              clearInterval(this.timer);
              let {
                identity: {
                  claims: { nickname, headimgurl }
                }
              } = res;
              this.nickname = nickname;
              this.headimgurl = headimgurl;
              localStorage.setItem(
                "bindData",
                JSON.stringify({
                  identity: res.identity.claims,
                  identity_token: res.identity_token,
                  session_id: JSON.parse(localStorage.getItem("session_id1"))
                    .session
                })
              );

              this.$refs.confirm
                .showConfirm({
                  contentText: `该微信尚未绑定过任何账户，需要您提供账户名和密码来完成绑定`,
                  callback: async () => {
                    this.fromType = "WECHAT_RZKC";
                    this.qrcode = true;
                    this.showCode = false;
                    this.defaultCode = false;
                    if (
                      this.getTabs &&
                      this.getTabs.length > 0 &&
                      this.tabSources.length === 0
                    ) {
                      this.tabSources = this.getTabs.map(item => {
                        for (const tab of this.allTabList) {
                          if (tab.id === item.id) {
                            return tab;
                          }
                        }
                      });
                    }
                    this.tabSources = this.filterIdentitySource(
                      this.tabSources
                    );

                    if (
                      this.otherLinks &&
                      this.otherLinks.length > 0 &&
                      this.otherLogins.length === 0
                    ) {
                      this.otherLogins = this.otherLinks.map(item => {
                        for (const tab of this.linkSources) {
                          if (tab.id === item.id) {
                            return tab;
                          }
                        }
                      });
                    }
                    this.otherLogins = this.filterIdentitySource(
                      this.otherLogins
                    );

                    if (
                      this.tabSources.length === 0 &&
                      this.otherLogins.length === 1
                    ) {
                      this.loginPageCtrl = null;
                      window.location = this.otherLogins[0].link;
                    } else {
                      this.specifiedSource = null;
                      this.curId = this.tabSources[0].id;
                      this.curItem = await this.filterItem(this.curId);
                      if (this.curItem.link) {
                        window.location = this.curItem.link;
                      } else {
                        this.linkPage = "noLinkPage";
                      }
                    }
                    return new Promise(resolve => resolve());
                  }
                })
                .catch(() => {
                  this.qrcode = false;
                  localStorage.removeItem("bindData");
                  localStorage.removeItem("session_id1");
                  this.toRefresh();
                });
            } else if (res.auth_token) {
              clearInterval(this.timer);
              this.$store.commit("auth_success", res);

              if (res["return_url"]) {
                window.location = res["return_url"];
              } else {
                this.$router.push({ path: "/" });
              }
              return "登录成功";
            }
          }
        } else {
          clearInterval(this.timer);
          this.isValid = true;
          localStorage.removeItem("session_id1");
        }
      }, 1000);
    },
    valid1() {
      this.timer = setInterval(async () => {
        if (this.validTimer > 0) {
          this.validTimer = this.validTimer - 1;
          if (this.validTimer % 2 === 0) {
            let res = await this.consumeSession();
            if (res.auth_token) {
              clearInterval(this.timer);
              this.$store.commit("auth_success", res);

              if (res["return_url"]) {
                window.location = res["return_url"];
              } else {
                this.$router.push({ path: "/" });
              }
              return "登录成功";
            }
          }
        } else {
          clearInterval(this.timer);
          this.isValid = true;
          this.scanSuccess = false;
          localStorage.removeItem("session_id1");
        }
      }, 1000);
    },
    toRefresh() {
      this.isValid = false;
      this.scanSuccess = false;
      this.validTimer = 60;
      document.getElementById("wechat_rzkc_code").innerHTML = "";
      this.startChallenge(this.curCode);
    },
    // 获取所有登录方式数据
    getTabList() {
      return this.$http
        .get(`api/source/login_methods`)
        .delegateTo(api_request)
        .then(res => {
          let tabList = res.credential.concat(res.external);
          this.allTabList = tabList;
          this.linkSources = res.external;
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    renderAsSingle(source) {
      this.curCode = source;
      let sourceCode = this.filterSource(source.id, this.allTabList).find(
        item =>
          item.type === "WECHAT_WEBSITE" ||
          item.type === "WECHAT_RZKC" ||
          item.type === "WECHAT_ENTERPRISE" ||
          item.type === "DING_TALK"
      );

      if (sourceCode) {
        this.qrcode = false;
        this.showIcon = true;
        this.codeTabSources = this.filterSource(sourceCode.id, this.allTabList);
        this.startChallenge(this.curCode);
      } else {
        localStorage.removeItem("session_id1");
        this.qrcode = true;
        this.showCode = false;
        this.curId = source.id;
        this.curItem = this.filterItem(source.id);
        this.tabList = this.filterSource(source.id, this.allTabList);
      }
    },
    defaultFirstQrcode(source) {
      localStorage.removeItem("session_id1");
      this.isValid = false;
      this.scanSuccess = false;
      clearInterval(this.timer);
      this.validTimer = 60;
      this.qrcode = false;
      this.curCode = source;
      let sourceCode = this.filterSource(source.id, this.allTabList).find(
        item =>
          item.type === "WECHAT_WEBSITE" ||
          item.type === "WECHAT_RZKC" ||
          item.type === "WECHAT_ENTERPRISE" ||
          item.type === "DING_TALK"
      );
      this.codeTabSources = this.filterSource(sourceCode.id, this.allTabList);
      this.startChallenge(this.curCode);
    },
    defaultRender(curId) {
      this.curId = curId;
      this.curItem = this.filterItem(curId);
      this.curCode = this.curItem;
      if (
        this.curItem.type === "WECHAT_ENTERPRISE" ||
        this.curItem.type === "WECHAT_RZKC" ||
        this.curItem.type === "WECHAT_WEBSITE" ||
        this.curItem.type === "DING_TALK"
      ) {
        this.codeTabSources = this.filterSource(this.curId, this.allTabList);
        this.startChallenge(this.curItem);
      }
    },
    filterItem(id) {
      let curItem = this.allTabList.find(item => item.id === id);
      return curItem;
    },
    filterSourceId(source_id, source_list) {
      let source = source_list.find(item => item.id === source_id);
      return source;
    },
    filterSource(source_id, source_list) {
      let sources = source_list.filter(item => item.id === source_id);
      return sources;
    },
    filterIdentitySource(source_list) {
      let sources = source_list.filter(item => item.identity_source);
      return sources;
    },
    fetchData() {
      return this.$http
        .get(`api/settings/login`)
        .delegateTo(api_request)
        .then(config => {
          this.sonData = config;

          let {
            tabs,
            otherLinks,
            qrcode,
            showDefaultQrcode,
            showUseInstruction,
            logoDesc,
            footContent,
            noticeDisplay,
            loginStyle,
            loginFrame: {
              loginBgColor,
              loginBgOpcity,
              loginFontColor,
              loginHighLight,
              loginSite
            }
          } = config;

          //获取tabs和qrcode
          this.getTabs = tabs;
          this.otherLinks = otherLinks;
          this.getQrcode = qrcode;

          let source_id = isSourceId();
          let source = this.filterItem(source_id);

          // 检测是否存在source_id
          if (source) {
            if (source.link) {
              location.href = source.link;
            } else {
              // 存在source_id&&非上下文环境
              if (source && !externalBrowser()) {
                this.loginPageCtrl = "show";
                this.specifiedSource = source;
                this.renderAsSingle(source);
              } else if (source && externalBrowser()) {
                // 存在source_id&&非上下文环境
                // 检测其source_id是否符合上下文环境的展示方式
                if (this.existSpecialType(source)) {
                  this.loginPageCtrl = null;
                  let ua = window.navigator.userAgent.toLowerCase();
                  this.curBrower(ua, source);
                } else {
                  this.loginPageCtrl = "show";
                  this.specifiedSource = source;
                  this.renderAsSingle(source);
                }
              }
            }
          } else if (externalBrowser()) {
            if (this.isExistSpecialType()) {
              let ua = window.navigator.userAgent.toLowerCase();
              this.curBrower(ua);
            } else {
              this.defaultRenderLogin();
            }
          } else {
            this.showDefaultQrcode = showDefaultQrcode;
            if (showDefaultQrcode) {
              this.firstQrcodeRender(qrcode);
            } else {
              this.defaultRenderLogin();
            }
          }
          this.scanCode = qrcode;
          this.noticeDisplay = noticeDisplay ? noticeDisplay : "dynamic";
          this.showUseInstruction = showUseInstruction;
          this.logoDesc = logoDesc;
          this.loginBgColor = HexToRgb(loginBgColor);
          this.loginBgTransparency = loginBgOpcity;
          this.loginSite = loginSite ? loginSite : "right";
          this.fontColor = loginFontColor;
          this.highlightColor = loginHighLight;
          this.footContent = footContent;
          if (loginStyle) {
            let styleText = loginStyle;
            let curstyle = document.createElement("style");
            curstyle.innerText = styleText;
            document.getElementsByTagName("body")[0].appendChild(curstyle);
          }
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async curBrower(ua, source) {
      // 检测当前浏览器环境
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == "wxwork"
      ) {
        if (source && source.type === "WECHAT_ENTERPRISE") {
          let wxEnterprise = source;
          this.curBrowserStart(wxEnterprise);
        } else {
          let wxEnterprise = this.filterTypeItem("WECHAT_ENTERPRISE");
          if (wxEnterprise) {
            this.curBrowserStart(wxEnterprise);
          } else {
            this.defaultRenderLogin();
          }
        }
      } else if (ua.match(/micromessenger/i) == "micromessenger") {
        if (
          source &&
          (source.type === "WECHAT_WEBSITE" ||
            source.type === "WECHAT_RZKC" ||
            source.type === "WECHAT_ENTERPRISE")
        ) {
          let curWX = this.filterItem(source.id);
          this.curBrowserStart(curWX);
        } else {
          let wx = this.filterTypeItem("WECHAT_WEBSITE");
          let wxRZKC = this.filterTypeItem("WECHAT_RZKC");
          let wxEnterprise = this.filterTypeItem("WECHAT_ENTERPRISE");
          if (wx) {
            this.curBrowserStart(wx);
          } else if (wxRZKC) {
            this.curBrowserStart(wxRZKC);
          } else if (wxEnterprise) {
            this.curBrowserStart(wxEnterprise);
          } else {
            this.defaultRenderLogin();
          }
        }
      } else if (ua.match(/dingtalk/i) == "dingtalk") {
        if (source && source.type === "DING_TALK") {
          let curDing = this.filterItem(source.id);
          this.curBrowserStart(curDing);
        } else {
          let dingTalk = this.filterTypeItem("DING_TALK");
          if (dingTalk) {
            this.curBrowserStart(dingTalk);
          } else {
            this.defaultRenderLogin();
          }
        }
      } else if (ua.match(/lark/i) == "lark") {
        if (source && source.type === "LARK") {
          let curLark = this.filterItem(source.id);
          this.curBrowserStart(curLark);
        } else {
          let lark = this.filterTypeItem("LARK");
          if (lark) {
            this.curBrowserStart(lark);
          } else {
            this.defaultRenderLogin();
          }
        }
      }
    },
    filterTypeItem(type) {
      let typeItem = this.allTabList.find(item => item.type === type);
      return typeItem;
    },
    async curBrowserStart(curRunItem) {
      if (curRunItem.type === "WECHAT_RZKC") {
        let session = await randomSessionId();
        this.$router.push({
          path: `/wechat/authorize`,
          query: {
            session: session
          }
        });
      } else {
        return this.$http
          .get(`api/source/${curRunItem.id}/start`)
          .delegateTo(api_request)
          .then(startRes => {
            let linkUrl = startRes.content.replace(
              /#/g,
              `&v=${new Date().getTime()}#`
            );
            window.location.href = linkUrl;
          })
          .catch(({ code, message }) => {
            throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    existSpecialType(source) {
      if (
        source.type === "WECHAT_WEBSITE" ||
        source.type === "WECHAT_RZKC" ||
        source.type === "WECHAT_ENTERPRISE" ||
        source.type === "DING_TALK" ||
        source.type === "LARK"
      ) {
        return true;
      }
    },
    isExistSpecialType() {
      let existItem = this.allTabList.find(
        item =>
          item.type === "WECHAT_WEBSITE" ||
          item.type === "WECHAT_RZKC" ||
          item.type === "WECHAT_ENTERPRISE" ||
          item.type === "DING_TALK" ||
          item.type === "LARK"
      );

      return existItem;
    },
    defaultRenderLogin() {
      if (
        (this.getTabs && this.getTabs.length > 0) ||
        (this.otherLinks && this.otherLinks.length > 0)
      ) {
        this.showCode = this.getQrcode;
        this.tabSources =
          this.getTabs && this.getTabs.length > 0
            ? this.getTabs.map(item => {
                for (const tab of this.allTabList) {
                  if (tab.id === item.id) {
                    return tab;
                  }
                }
              })
            : [];

        this.otherLogins =
          this.otherLinks && this.otherLinks.length > 0
            ? this.otherLinks.map(item => {
                for (const tab of this.linkSources) {
                  if (tab.id === item.id) {
                    return tab;
                  }
                }
              })
            : [];
      } else {
        this.showCode = false;
        this.tabSources = this.allTabList.filter(
          item => item.id === this.getQrcode
        );
      }
      if (this.tabSources.length > 0) {
        this.loginPageCtrl = "show";
        setTimeout(() => {
          this.defaultRender(this.tabSources[0].id);
        }, 0);
      } else {
        if (
          this.otherLogins &&
          this.otherLogins.length === 1 &&
          this.getQrcode === ""
        ) {
          window.location.href = this.otherLogins[0].link;
        } else {
          this.loginPageCtrl = "show";
        }
      }
    },
    coverOtherLogins() {
      if (
        (this.getTabs && this.getTabs.length > 0) ||
        (this.otherLinks && this.otherLinks.length > 0)
      ) {
        this.tabSources =
          this.getTabs && this.getTabs.length > 0
            ? this.getTabs.map(item => {
                for (const tab of this.allTabList) {
                  if (tab.id === item.id) {
                    return tab;
                  }
                }
              })
            : [];

        if (this.tabSources && this.tabSources.length > 0) {
          this.curId = this.tabSources[0].id;
          this.curItem = this.tabSources[0];
        }
        this.otherLogins =
          this.otherLinks && this.otherLinks.length > 0
            ? this.otherLinks.map(item => {
                for (const tab of this.linkSources) {
                  if (tab.id === item.id) {
                    return tab;
                  }
                }
              })
            : [];
      }
    },
    firstQrcodeRender(qrcode) {
      this.loginPageCtrl = "show";
      let source = this.filterItem(qrcode);
      if (
        (this.getTabs && this.getTabs.length > 0) ||
        (this.otherLinks && this.otherLinks.length > 0)
      ) {
        this.defaultCode = true;
        this.defaultCodeImg = true;
      }
      this.defaultFirstQrcode(source);
    },
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          setTimeout(async () => {
            let cellMode = localStorage.getItem("cellMode");
            let iconStatus = localStorage.getItem("iconStatus");
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("cellMode", cellMode);
            localStorage.setItem("iconStatus", iconStatus);
            await this.$store.commit("logout");
            this.$router.push("/login");
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 微信调finish
    wechatSiteLogin(redirect_uri, code) {
      if (redirect_uri && code) {
        this.$http
          .post(`${redirect_uri}`, { code })
          .delegateTo(api_request)
          .then(data => {
            console.log("data:", data);
            this.BindModes();
          })
          .catch(({ code }) => {
            throw `登录失败：${this.$t("api." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    BindModes() {
      if (
        this.otherLinks &&
        this.otherLinks.length > 0 &&
        this.otherLogins.length === 0
      ) {
        this.otherLogins = this.otherLinks.map(item => {
          for (const tab of this.linkSources) {
            if (tab.id === item.id) {
              return tab;
            }
          }
        });
      }
      this.otherLogins = this.filterIdentitySource(this.otherLogins);

      if (this.tabSources.length === 0 && this.otherLogins.length === 1) {
        this.loginPageCtrl = null;
        window.location = this.otherLogins[0].link;
      } else {
        this.specifiedSource = null;
        this.curId = this.tabSources[0].id;
        this.curItem = this.filterItem(this.curId);
        if (this.curItem.link) {
          window.location = this.curItem.link;
        } else {
          this.linkPage = "noLinkPage";
        }
      }
    }
  },
  computed: {
    styleObject() {
      return {
        "--color": this.fontColor
      };
    }
  },
  watch: {
    curItem() {
      if (this.curItem.type === "DING_TALK") {
        this.dingEvent();
      }
    },
    curCode() {
      if (this.curCode.type === "DING_TALK") {
        this.dingEvent();
      }
    }
  },
  components: {
    Snackbar,
    ConfirmBox,
    Logo,
    Notice,
    StaticNotice,
    OtherLink,
    WechatBind,
    FootDisplay
  }
};
</script>

<style lang="less" scoped>
.leftStyle {
  width: 100%;
  padding: 0 14%;
  position: absolute;
  top: 26%;
  left: 0;
}

.rightStyle {
  width: 100%;
  padding: 0 14%;
  position: absolute;
  top: 26%;
  right: 0;
}

.centerStyle {
  position: absolute;
  top: 26%;
  right: 50%;
  transform: translateX(190px);
}

/deep/ .tab-item:not(.v-tab--active) {
  color: var(--color) !important;
}

.ValidCode {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    display: inline-block;
  }
}

.verify {
  /deep/ .v-input__control {
    border: none;
    /deep/ .v-input__slot {
      box-shadow: none !important;
    }
  }
}

/deep/.v-slide-group__prev {
  align-items: center;
  display: flex;
  flex: 0 1 5px;
  justify-content: center;
  min-width: 5px;
}

/deep/ .v-tabs-bar {
  height: 42px;
}
/deep/.theme--light.v-tabs > .v-tabs-bar {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
  color: rgba(255, 255, 255, 1);
}

.theme--light.v-tabs-items,
.theme--light.v-card {
  background-color: transparent;
}
/deep/ .v-card__text {
  padding-top: 0;
}
/deep/ .col.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

/deep/ .v-input__control {
  min-height: 44px;
  .v-text-field__slot input {
    padding: 4px 0 4px 4px;
  }
}
</style>
